import {createSlice, PayloadAction, createAsyncThunk, isAction, createAction } from "@reduxjs/toolkit"

export type variant_category_type = {
     value: string,
     code: string
}
export type category_sku_type = {
     label: string,
     sku: string,
}
export interface productManagementInitialState {
     product_base_sku: string | null,
     bulk_operation: boolean,
     current_active_state: string,
     active_sku: string | null,
     variant_category_map: Array<variant_category_type> | null,
     product_variant_sku: Array<string> | null,
     variant_sku_map: Array<category_sku_type> | null,

}

const initialState: productManagementInitialState= {
     product_base_sku: null,
     bulk_operation: false,
     current_active_state: "PRODUCT_INFO",
     active_sku: null,
     variant_category_map: [],
     product_variant_sku: [],
     variant_sku_map: []
}
export const productSlice = createSlice({
     name:'product',
     initialState,
     reducers: {
         setActiveState: (state, action:PayloadAction<string>) => {
             const current_state = action.payload
             state.current_active_state = current_state
         },
         setProductBaseSku: (state, action:PayloadAction<string>) => {
             const sku = action.payload
             state.product_base_sku = sku
         },
         setProductVariantSku: (state, action:PayloadAction<Array<string>>) => {
            const variant_sku = action.payload
            state.product_variant_sku = variant_sku
            // here we map the product_variant_sku to variant_sku_map
            for(var i = 0; i < state.product_variant_sku.length; i++)
            {
                 var variant_name_array = []
                 // lets then decode the variant sku to the respective codes
                 var sku_code = state.product_variant_sku[i].slice(7)
                 // lets then break the sku_code into its counter parts
                 for (let j = 0; j < sku_code.length; j += 4) {
                    var code_value = sku_code.slice(j, j + 4)
                    var name = state.variant_category_map?.filter((val) => val.code === "V" + code_value)[0].value
                    variant_name_array.push(name)
                 }
                 var variant_name = variant_name_array.join(" ")
                 state.variant_sku_map?.push(
                      {
                         label: variant_name,
                         sku: state.product_variant_sku[i]
                      }
                 )               
            }
         },
         setBulkOperation: (state, action:PayloadAction<boolean>) => {
             state.bulk_operation = action.payload
         },
         setActiveSku: (state, action:PayloadAction<string>) => {
               state.active_sku = action.payload
         },
         setVariantCategoryMap: (state, action:PayloadAction<any>) => {
             // lets first check if the code is already inserted
             var code_exists = state.variant_category_map?.find((val) => val.code === action.payload.code)
             if(code_exists === undefined) {
                state.variant_category_map = [...state.variant_category_map!, action.payload]
             }
         },
         removeVariantCategoryMap: (state, action:PayloadAction<variant_category_type>) => {
            state.variant_category_map = state.variant_category_map!.filter((val) => val.code === action.payload.code)
         },
     },
     extraReducers: (builder) => {
          builder.addCase(resetState, () => initialState); // Reset to initial state
        },
})
export default  productSlice.reducer
export const {setActiveState, setProductBaseSku, setProductVariantSku, setBulkOperation, setVariantCategoryMap, removeVariantCategoryMap, setActiveSku} = productSlice.actions
export const resetState = createAction('product/reset');